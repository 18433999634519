<script>
import MiniMessage from './MiniMessage.vue';

export default {
  components: {
    MiniMessage,
  },
};
</script>

<template>
  <mini-message icon="no_accounts">You're not signed in.</mini-message>
</template>
